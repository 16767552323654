.animated-checkbox-input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.animated-checkbox {
    display: inline-block;
    height: 75px;
    width: 75px;
    padding: 10px;
    background: #fff;
    border: 2px #ddd solid;
    margin-right: 4px;
    border-radius: 50px;
}

.bounce {
    animation: bounce 1s ease infinite;

    @keyframes bounce {
    
        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }
    
        40% {
            transform: translateY(-30px);
        }
    
        60% {
            transform: translateY(-15px);
        }
    }
}
