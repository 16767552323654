.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 80vh;
    background-color: #fff;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    margin-top: 5%;
}