body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Gotham Bold";
  /*Can be any text*/
  src: local("GOTHAM-BOLD"),
    url("./assets/fonts/GOTHAM-BOLD.TTF") format("truetype");
}

@font-face {
  font-family: "Lato";
  /*Can be any text*/
  src: local("Lato-Medium"),
    url("./assets/fonts/Lato-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Bold";
  /*Can be any text*/
  src: local("Lato-Semibold"),
    url("./assets/fonts/Lato-Semibold.ttf") format("truetype");
}